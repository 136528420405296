<template>
    <div>
        <div v-if="twoShow === 1">
            <div style="display: flex;align-items: center">
                <el-button type="primary" size="mini" @click="returnTagList">返回</el-button>
                <h3 style="margin-left: 15px">{{ component.title }}</h3>
            </div>
            <div>
                <h4>默认构件</h4>
                <div class="default-component">
                    <div class="add" @click="add(1)">
                        <i class="el-icon-plus"></i>
                    </div>
                    <el-checkbox-group style="display: flex;overflow-x: auto;width: 100%;">
                        <div :class="activeId === item.middle_id ? 'componentBox' : ''" v-for="item in defaultData"
                            :key='item.model_id'
                            style="margin-right: 10px;margin-top: 10px;position: relative;transition: all 0.5s;"
                            @click="getReplaceableList(item.middle_id)">
                            <el-card shadow="hover">
                                <div :style="{ margin: '0' }" class="btnBody">
                                    <div class="imgBox">
                                        <img style="width: 180px; height: 180px;object-fit: cover;"
                                            :src="item.model_icon" alt="" />
                                        <div class="delete" @click.stop="deleteComponent(item.middle_id)">
                                            <p>删除</p>
                                        </div>
                                    </div>
                                    <div style="height: 105px;font-size: 14px;">
                                        <div>
                                            <el-tooltip class="item" effect="dark" :content="item.model_name"
                                                placement="top">
                                                <div>
                                                    模型名字：{{ item.model_name }}
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :content="item.model_number"
                                                placement="top">
                                                <div>
                                                    模型编号：{{ item.model_number }}
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :content="item.tag_number"
                                                placement="top">
                                                <div>
                                                    分类标签：{{ item.tag_number }}
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
            <div v-if="activeId">
                <h4>可替换</h4>
                <div class="replaceable">
                    <div class="add" @click="add(3)" style="height: 320px;">
                        <i class="el-icon-plus"></i>
                    </div>
                    <el-checkbox-group style="display: flex;overflow-x: auto;width: 100%;">
                        <div v-for="item in replaceableData" :key='item.middle_id'
                            style="margin-right: 10px;margin-top: 10px;">
                            <el-card shadow="hover">
                                <div :style="{ margin: '0' }" class="btnBody">
                                    <div class="imgBox">
                                        <img style="width: 180px; height: 180px;object-fit: cover;"
                                            :src="item.model_icon" alt="" />
                                        <div class="delete" @click="deleteReplaceable(item.middle_id)">
                                            <p>删除</p>
                                        </div>
                                    </div>
                                    <div style="height: 140px;font-size: 14px;">
                                        <div>
                                            <el-tooltip class="item" effect="dark" :content="item.model_name"
                                                placement="top">
                                                <div>
                                                    模型名字：{{ item.model_name }}
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :content="item.model_number"
                                                placement="top">
                                                <div>
                                                    模型编号：{{ item.model_number }}
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :content="item.tag_number"
                                                placement="top">
                                                <div>
                                                    分类标签：{{ item.tag_number }}
                                                </div>
                                            </el-tooltip>
                                        </div>
                                        <div style="margin: 0 10px;">
                                            <el-button size="mini" style="width: 100%;" type="primary"
                                                @click="SetAsDefault(item)">设为默认</el-button>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
            <div v-if="activeId">
                <h4>搭配使用</h4>
                <div class="collocation">
                    <div class="add" @click="add(2)">
                        <i class="el-icon-plus"></i>
                    </div>
                    <el-checkbox-group style="display: flex;overflow-x: auto;width: 100%;">
                        <div v-for="item in collocationData" :key='item.middle_id'
                            style="margin-right: 10px;margin-top: 10px;">
                            <el-card shadow="hover">
                                <div :style="{ margin: '0' }" class="btnBody">
                                    <div class="imgBox">
                                        <img style="width: 180px; height: 180px;object-fit: cover;"
                                            :src="item.model_icon" alt="" />
                                        <div class="delete" @click="deleteCollocation(item.middle_id)">
                                            <p>删除</p>
                                        </div>
                                    </div>
                                    <div style="height: 105px;font-size: 14px;">
                                        <div>
                                            <el-tooltip class="item" effect="dark" :content="item.model_name"
                                                placement="top">
                                                <div>
                                                    模型名字：{{ item.model_name }}
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :content="item.model_number"
                                                placement="top">
                                                <div>
                                                    模型编号：{{ item.model_number }}
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip class="item" effect="dark" :content="item.tag_number"
                                                placement="top">
                                                <div>
                                                    分类标签：{{ item.tag_number }}
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </el-checkbox-group>
                </div>
            </div>
        </div>
        <div v-if="twoShow === 2">
            <checkboxComponent :middle_id="middle_id" :number="number" :component="component" @getBack="getBack">
            </checkboxComponent>
        </div>
    </div>
</template>

<script>
import checkboxComponent from './checkboxComponent'
import { getmiddlemodellist2, setmiddlemodeldel, getmiddlelinklist, setmiddlelinkdel, getmiddlebindlist, setmiddlebinddel, setmiddlelinkalternate } from '@/common/js/systemList.js'
export default {
    name: "relevancyComponent",
    components: { checkboxComponent },
    props: ['component'],
    data() {
        return {
            twoShow: 1,
            activeId: 0,
            defaultData: [], // 默认构件列表数据
            replaceableData: [],// 可替换列表数据
            number: 0,
            collocationData: [], // 搭配使用列表数据
            middle_id: 0,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList() {
            let dt = {
                system_id: this.component.id
            }
            getmiddlemodellist2(dt).then(res => {
                if (res.code === 200) {
                    this.defaultData = res.result
                }
            })
        },
        returnTagList() {
            this.$emit('returnTagList')
        },
        add(number) {
            this.number = number
            this.twoShow = 2
        },
        getBack() {
            this.getList()
            if (this.activeId) {
                this.getReplaceableList(this.activeId)
            }
            this.twoShow = 1
        },
        // 删除默认构件
        deleteComponent(id) {
            this.$confirm('此操作将删除该构件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (this.activeId === id) {
                    this.activeId = 0
                    this.replaceableData = []
                    this.collocationData = []
                }
                setmiddlemodeldel({ middle_id: id }).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.getList()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 获取可替换列表 搭配使用列表
        getReplaceableList(id) {
            this.activeId = id
            // this.component.middle_id = id
            this.middle_id = id
            let dt = {
                system_id: this.component.id,
                middle_model_id: id
            }
            getmiddlelinklist(dt).then(res => {
                if (res.code === 200) {
                    this.replaceableData = res.result
                }
            })
            getmiddlebindlist(dt).then(res => {
                if (res.code === 200) {
                    this.collocationData = res.result
                }
            })
        },
        //删除搭配使用构件
        deleteCollocation(id) {
            this.$confirm('此操作将删除该构件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let dt = {
                    middle_ids: [id]
                }
                setmiddlebinddel(dt).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.getReplaceableList(this.activeId)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 删除可替换构件
        deleteReplaceable(id) {
            this.$confirm('此操作将删除该构件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let dt = {
                    middle_ids: [id]
                }
                setmiddlelinkdel(dt).then(res => {
                    if (res.code == 200) {
                        this.$message.success(res.msg)
                        this.getReplaceableList(this.activeId)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 设为默认
        SetAsDefault(data) {
            let dt = {
                system_id: this.component.id,
                link_id: data.middle_id,
                middle_id: this.activeId
            }
            setmiddlelinkalternate(dt).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.getList()
                    this.getReplaceableList(this.activeId)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.default-component,
.collocation,
.replaceable {
    display: flex;

    .add {
        margin: 10px 10px 0 0;
        box-sizing: border-box;
        width: 180px;
        height: 285px;
        text-align: center;
        border: 1px dashed #000;
        cursor: pointer;

        i {
            line-height: 285px;
            font-size: 50px;
        }
    }
}

.item {
    width: 160px;
    margin: 0 10px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.componentBox {
    // border-radius: 3px;
    // border: 2px solid rgba(0, 0, 0, 0.2);
    border: 2px solid;
    border-style: outset;
}

.el-card {
    width: 180px;
}

.imgBox {
    position: relative;
    width: 180px;
    height: 180px;
    font-size: 0;

    &:hover .delete {
        display: block;
    }

    .delete {
        position: absolute;
        bottom: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.74);
        transition: all 0.5s;
        cursor: pointer;

        p {
            margin: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            color: #fff;
            font-size: 16px;
            line-height: 30px;
        }
    }
}
</style>