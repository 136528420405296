import $http from '../../utils/http.js';

// 体系标签 - 列表
export function newsystemtagGettaglist(data) {
    return $http.get('/newsystemtag/gettaglist', data, 'loadingDiv', 'off');
}

// 体系标签 - 获取模型分类-----------------------------------------------------------------------------------------------------------------------
export function modelsConfig_list(data) {
    return $http.get('/modelsConfig_list', data, 'loadingDiv', 'off');
}

// 体系标签 - 选择品牌-----------------------------------------------------------------------------------------------------------------------
export function getcanselectbrand(data) {
    return $http.get('/newsystem/getcanselectbrand', data, 'loadingDiv');
}

// 体系标签 - 已关联列表-----------------------------------------------------------------------------------------------------------------------
export function getmiddlemodel(data) {
    return $http.get('/newsystemmodel/getmiddlemodel', data, 'loadingDiv');
}

// 体系标签 - 未关联列表-----------------------------------------------------------------------------------------------------------------------
export function getcanselectmodel(data) {
    return $http.get('/newsystemmodel/getcanselectmodel', data, 'loadingDiv');
}
// export function getcanselectmodel(data) {
//     return $http.get('/newsystemmodel/getcanselectmodel', data, 'loadingDiv', 'off');
// }

//体系模型 - 添加/删除标签 单模型-----------------------------------------------------------------------------------------------------------------------
export function setmiddlemodelsave(data) {
    return $http.post('/newsystemmodel/setmiddlemodelsave', data, 'loadingDiv');
}

//体系模型 - 详情标签列表-----------------------------------------------------------------------------------------------------------------------
export function getmiddlemodelinfotaglist(data) {
    return $http.post('/newsystemmodel/getmiddlemodelinfotaglist', data, 'loadingDiv');
}

//体系模型 - 选择标签列表-----------------------------------------------------------------------------------------------------------------------
export function getcanselecttag(data) {
    return $http.post('/newsystemmodel/getcanselecttag', data, 'loadingDiv');
}

//体系模型 - 批量添加标签 多模型-----------------------------------------------------------------------------------------------------------------------
export function setmiddlemodelsaveall(data) {
    return $http.post('/newsystemmodel/setmiddlemodelsaveall', data, 'loadingDiv');
}

//体系标签 - 删除-----------------------------------------------------------------------------------------------------------------------
export function newsystemtagGettagdel(data) {
    return $http.post('/newsystemtag/gettagdel', data, 'loadingDiv');
}

//体系标签 - 修改-----------------------------------------------------------------------------------------------------------------------
export function settagupdate(data) {
    return $http.post('/newsystemtag/settagupdate', data, 'loadingDiv');
}
// 体系 - 新增
export function setsystemadd(data) {
    return $http.post('/newsystem/setsystemadd', data, 'loadingDiv');
}
// 体系 - 列表
export function getsystemlist(data) {
    return $http.get('/newsystem/getsystemlist', data, 'loadingDiv');
}
// 体系 - 修改状态-----------------------------------------------------------------------------------------------------------------------
export function setsystemstatus(data) {
    return $http.post('/newsystem/setsystemstatus', data, 'loadingDiv');
}
// 体系 - 删除-----------------------------------------------------------------------------------------------------------------------
export function setsystemdel(data) {
    return $http.post('/newsystem/setsystemdel', data, 'loadingDiv');
}
// // 体系 - 选择标签
// export function getcanselecttag() {
//     return $http.get('/newsystem/getcanselecttag',);
// }
// 体系 - 详情-----------------------------------------------------------------------------------------------------------------------
export function setsysteminfo(data) {
    return $http.post('/newsystem/setsysteminfo', data, 'loadingDiv');
}
// 体系 - 编辑-----------------------------------------------------------------------------------------------------------------------
export function setsystemupdate(data) {
    return $http.post('/newsystem/setsystemupdate', data, 'loadingDiv');
}
// 体系分类 - 列表-----------------------------------------------------------------------------------------------------------------------
export function getsystemslassifylist(data) {
    return $http.get('/newsystemclassify/getsystemslassifylist', data, 'loadingDiv');
}
// 体系标签 - 修改状态-----------------------------------------------------------------------------------------------------------------------
export function setsystemclassifystatus(data) {
    return $http.post('/newsystemclassify/setsystemclassifystatus', data, 'loadingDiv');
}


// 生成配置-----------------------------------------------------------------------------------------------------------------------
export function matchinglist(data) {
    return $http.exportsTxtNew('/systemmatching/matchinglist', data, 'loadingDiv', '');
}
// 体系 - 选择标签-----------------------------------------------------------------------------------------------------------------------
export function getcanselecttag2(data) {
    return $http.get('/newsystem/getcanselecttag', data, 'loadingDiv');
}
// 体系 - 已关联的模型--------------------------------------------------------------------------------------------------------------------
export function getmiddlemodellist(data) {
    return $http.get('/newsystem/getmiddlemodellist', data, 'loadingDiv');
}
// 关联体系标签 - 列表
export function gettagmiddlelist(data) {
    return $http.get('/newsystemtagmiddle/gettagmiddlelist', data, 'loadingDiv');
}
// 关联体系标签 - 编辑
export function settagmiddlesave(data) {
    return $http.post('/newsystemtagmiddle/settagmiddlesave', data, 'loadingDiv');
}
// 获取模型分类列表全部信息并分级拼接（json） -----------------------------------------------------------------------------------------
export function getmodelclassifylist(data) {
    return $http.exportsTxtNew('/newsystem/getmodelclassifylist', data, 'loadingDiv', '');
}
// 模型分类 - 列表 -----------------------------------------------------------------------------------------
export function getClassifylist(data) {
    return $http.get('/modelsclassify/getClassifylist', data, 'loadingDiv');
}
// 模型分类 - 添加 -----------------------------------------------------------------------------------------
export function setclassifyadd(data) {
    return $http.post('/modelsclassify/setclassifyadd', data, 'loadingDiv');
}
// 模型分类 - 修改 -----------------------------------------------------------------------------------------
export function setclassifyupdate(data) {
    return $http.post('/modelsclassify/setclassifyupdate', data, 'loadingDiv');
}
// 模型分类 - 删除 -----------------------------------------------------------------------------------------
export function setclassifydel(data) {
    return $http.post('/modelsclassify/setclassifydel', data, 'loadingDiv');
}

// 已关联模型(默认模型) - 列表
export function getmiddlemodellist2(data) {
    return $http.get('/newsystemmiddlemodel/getmiddlemodellist', data, 'loadingDiv');
}
// 关联模型 - 可关联模型列表
export function getselectdefaultmodellist(data) {
    return $http.get('/newsystemmiddlemodel/getselectdefaultmodellist', data, 'loadingDiv');
}
// 关联模型(默认模型) - 添加
export function setmiddlemodeladd(data) {
    return $http.post('/newsystemmiddlemodel/setmiddlemodeladd', data,);
}
// 关联模型(默认模型) - 删除
export function setmiddlemodeldel(data) {
    return $http.post('/newsystemmiddlemodel/setmiddlemodeldel', data,);
}
// 关联相似模型 - 已关联模型列表
export function getmiddlelinklist(data) {
    return $http.get('/newmodelmiddlelink/getmiddlelinklist', data, 'loadingDiv');
}
// 关联绑定模型 - 已关联模型列表
export function getmiddlebindlist(data) {
    return $http.get('/newmodelmiddlebind/getmiddlebindlist', data, 'loadingDiv');
}
// 关联模型(默认模型) - 删除
export function setmiddlelinkdel(data) {
    return $http.post('/newmodelmiddlelink/setmiddlelinkdel', data,);
}
// 关联相似模型 - 添加
export function setmiddlelinkadd(data) {
    return $http.post('/newmodelmiddlelink/setmiddlelinkadd', data,);
}
// 关联绑定模型 - 删除
export function setmiddlebinddel(data) {
    return $http.post('/newmodelmiddlebind/setmiddlebinddel', data,);
}
// 关联模型 - 可关联模型列表（绑定、相似）
export function getselectmodellist(data) {
    return $http.get('/newsystemmiddlemodel/getselectmodellist', data, 'loadingDiv');
}
// 关联绑定模型 - 添加
export function setmiddlebindadd(data) {
    return $http.post('/newmodelmiddlebind/setmiddlebindadd', data,);
}
// 关联相似模型 - 交替
export function setmiddlelinkalternate(data) {
    return $http.post('/newmodelmiddlelink/setmiddlelinkalternate', data, 'loadingDiv');
}
// 子体系模型绑定规格 - 列表
export function getmiddlemodelspecificationlist(data) {
    return $http.get('/newsystemmiddlemodelspec/getmiddlemodellist', data, 'loadingDiv');
}
// 子体系模型绑定规格 - 编辑
export function setmiddlemodelsave2(data) {
    return $http.post('/newsystemmiddlemodelspec/setmiddlemodelsave', data, 'loadingDiv');
}

// 子体系关联子体系 - 可关联列表
export function getoptionallist(data) {
    return $http.get('/newsystemmiddlesystem/getoptionallist', data);
}
// 子体系关联子体系 - 已关联列表
export function getselectedlist(data) {
    return $http.get('/newsystemmiddlesystem/getselectedlist', data);
}
// 子体系关联子体系 - 编辑
export function setmiddlesystemsave(data) {
    return $http.post('/newsystemmiddlesystem/setmiddlesystemsave', data, 'loadingDiv');
}