<template>
    <div style="display: flex; flex-direction: column" class="bgc">
        <div style="height: 50px; display: flex; align-items: center; justify-content: space-between">
            <div>
                <el-button type="primary" style="margin-right: 20px;" size="mini" @click="getBack">返回
                </el-button>
                <el-input size="mini" style="width: 300px; margin-right: 10px" v-model="keywords" placeholder="请输入内容"
                    @change="searchList">
                </el-input>
                <el-button type="primary" style="margin-left: 20px;" size="mini" @click="searchList">查询</el-button>
            </div>
            <div>
                <el-button type="primary" size="mini" @click="batchAdd">确定</el-button>
            </div>
        </div>
        <div style=" text-align: left">
            <categoryVue v-if="this.number !== 3" :categoryData="data" :events="events"></categoryVue>
        </div>
        <div style="margin-bottom: 60px">
            <el-checkbox-group v-model="checkedCities" style="display: flex;flex-wrap: wrap;">
                <div v-for="(item, i) in modelData" :key="i" style="margin-right: 10px;margin-bottom: 10px;">
                    <el-card shadow="hover">
                        <div :style="{ margin: '0' }" class="btnBody">
                            <div style="position: relative; font-size: 0">
                                <el-checkbox :label="item.m_id"> </el-checkbox>
                                <img style="width: 220px; height: 220px;object-fit: cover;" :src="item.m_icon" alt=""
                                    @click="addImgId(item.m_id)" />
                            </div>
                            <div style="height: 105px;font-size: 14px;">
                                <div>
                                    <el-tooltip class="item" effect="dark" :content="item.m_name" placement="top">
                                        <div>
                                            模型名字：{{ item.m_name }}
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" :content="item.m_number" placement="top">
                                        <div>
                                            模型编号：{{ item.m_number }}
                                        </div>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" :content="item.tag_number" placement="top">
                                        <div>
                                            分类标签：{{ item.tag_number }}
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </el-checkbox-group>
        </div>
        <div v-if="page.total > 0"
            style="text-align: center; position: absolute; bottom: 0px; left: 50%; transform: translateX(-50%)">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="page.page" :page-sizes="[10, 30, 40, 50]" :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="page.total">
            </el-pagination>
        </div>
        <div v-else>
            <h4 style="text-align: center;color: #bbb;">暂无数据</h4>
        </div>
    </div>
</template>

<script>
import categoryVue from '@/components/category.vue';
import {
    modelsConfig_list,
    getselectdefaultmodellist,
    setmiddlemodeladd,
    getselectmodellist,
    setmiddlebindadd,
    setmiddlelinkadd
} from '@/common/js/systemList.js';
export default {
    // 组件名称
    name: 'checkboxComponent',
    // 组件参数 接收来自父组件的数据
    props: {
        component: {
            type: Object
        },
        number: {
            type: Number
        },
        middle_id: {
            type: Number
        }
    },
    // 局部注册的组件
    components: {
        categoryVue
    },
    // 组件状态值
    data() {
        let that = this;
        return {
            imgArr: [],
            lookList: false,
            events: {
                selectAllEvent(data) {
                    const { id } = data;
                    that.modelID = id;
                    if (that.number == 1) {
                        that.getList();
                    } else if (that.number == 2) {
                        that.getCollocationList()
                    } else if (that.number == 3) {
                        that.getReplaceableList()
                    }
                },
                selectSingleEvent(data) {
                    const { id } = data;
                    that.modelID = id;
                    if (that.number == 1) {
                        that.getList();
                    } else if (that.number == 2) {
                        that.getCollocationList()
                    } else if (that.number == 3) {
                        that.getReplaceableList()
                    }
                }
            },
            // 层级数据
            data: [],
            // 分页
            page: {
                page: 1,
                pageSize: 10,
                total: 0
            },
            // 体系ID
            systemId: 0,
            // 选中ID
            checkedCities: [],
            // 模型ID
            mID: '',
            modelData: [],
            modelID: '',
            keywords: ""
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        lookList(val) {
            if (val == false) {
                this.checkedCities = []
            }
        }
    },
    // 组件方法
    methods: {
        // 获取默认构件可添加列表
        getList() {
            let dt = {
                system_id: this.component.id,
                classify_id: this.modelID,
                page: this.page.page,
                limit: this.page.pageSize,
                keywords: this.keywords
            }
            getselectdefaultmodellist(dt).then(res => {
                if (res.code === 200) {
                    this.modelData = res.result.data
                    this.page.total = res.result.total
                }
            })
        },
        // 获取搭配使用添加列表
        getCollocationList() {
            let dt = {
                system_id: this.component.id,
                middle_id: this.middle_id,
                classify_id: this.modelID,
                page: this.page.page,
                limit: this.page.pageSize,
                keywords: this.keywords,
                type: 'SelectBind'
            }
            getselectmodellist(dt).then(res => {
                if (res.code === 200) {
                    this.modelData = res.result.data
                    this.page.total = res.result.total
                }
            })
        },
        // 获取可替换添加列表
        getReplaceableList() {
            let dt = {
                system_id: this.component.id,
                middle_id: this.middle_id,
                classify_id: this.modelID,
                page: this.page.page,
                limit: this.page.pageSize,
                keywords: this.keywords,
                type: 'SelectLink'
            }
            getselectmodellist(dt).then(res => {
                if (res.code === 200) {
                    this.modelData = res.result.data
                    this.page.total = res.result.total
                }
            })
        },
        // 提交多选
        batchAdd() {
            if (this.checkedCities.length == 0) {
                return this.$message.warning('请选择模型')
            }
            if (this.number == 1) {
                let dt = {
                    system_classify_id: this.component.system_classify_id,
                    system_id: this.component.id,
                    model_ids: this.checkedCities
                }
                setmiddlemodeladd(dt).then(res => {
                    if (res.code == 200) {
                        // this.getList()
                        this.$emit('getBack')
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
            if (this.number == 2) {
                let dt = {
                    system_classify_id: this.component.system_classify_id,
                    system_id: this.component.id,
                    middle_model_id: this.middle_id,
                    model_ids: this.checkedCities
                }
                setmiddlebindadd(dt).then(res => {
                    if (res.code == 200) {
                        // this.getCollocationList()
                        this.$emit('getBack')
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
            if (this.number == 3) {
                let dt = {
                    system_classify_id: this.component.system_classify_id,
                    system_id: this.component.id,
                    middle_model_id: this.middle_id,
                    model_ids: this.checkedCities
                }
                setmiddlelinkadd(dt).then(res => {
                    if (res.code == 200) {
                        // this.getReplaceableList()
                        this.$emit('getBack')
                        this.$message.success(res.msg)
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        addImgId(id) {
            let isOK = false
            let ind = null
            this.checkedCities.forEach((element, i) => {
                if (element == id) {
                    isOK = true
                    ind = i
                }
            });
            if (isOK) {
                this.checkedCities.splice(ind, 1)
            } else {
                this.checkedCities.push(id)
            }
        },
        // -------------------------------------------------------------------------------------------------------------------------------------------------
        // 公共方法
        addImgList(item) {
            let arr = []
            if (item) {
                item.forEach(element => {
                    if (this.checkImgType(element)) {
                        arr.push(element)
                    }
                });
            }
            return arr
        },
        // 校验图片
        checkImgType(obj) {
            //获取最后一个.的位置
            var index = obj.lastIndexOf(".");
            //获取后缀
            var ext = obj.substr(index + 1);
            //判断是否是图片
            var flag = this.isAssetTypeAnImage(ext);
            if (flag) {
                // this.imgArr.push(obj)
                return true
            }
        },
        // 验证是否是图片格式
        isAssetTypeAnImage(ext) {
            return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1;
        },
        // 初始化
        init() {
            modelsConfig_list().then(res => {
                if (res.code == 200) {
                    this.data = res.result.config_list;
                    this.data.splice(0, 0, { name: '所有', show: 0, id: 0, level: 1, children: [] });
                    this.data.forEach(e => {
                        this.addAll(e);
                    });
                }
            });
        },
        searchList() {
            this.page.page = 1
            if (this.number == 1) {
                this.getList();
            } else if (this.number == 2) {
                this.getCollocationList()
            } else if (this.number == 3) {
                this.getReplaceableList()
            }
        },

        // 在每个数组前添加所有字段
        addAll(e) {
            if (e.children != '') {
                e.children.splice(0, 0, { name: '所有', show: 0, id: e.id, level: e.level + 1, children: [] });
                e.children.forEach(el => {
                    this.addAll(el);
                });
            } else {
                return;
            }
        },
        // 处理分页
        handleSizeChange(size) {
            this.page.pageSize = size;
            if (this.number == 1) {
                this.getList();
            } else if (this.number == 2) {
                this.getCollocationList()
            } else if (this.number == 3) {
                this.getReplaceableList()
            }
        },
        handleCurrentChange(page) {
            this.page.page = page;
            if (this.number == 1) {
                this.getList();
            } else if (this.number == 2) {
                this.getCollocationList()
            } else if (this.number == 3) {
                this.getReplaceableList()
            }
        },
        //  返回
        getBack() {
            this.$emit('getBack')
        }
    },
    created() {
        this.init();
        if (this.number === 1) {
            this.getList()
        } else if (this.number === 2) {
            this.getCollocationList()
        } else if (this.number === 3) {
            this.getReplaceableList()
        }
    }
};
</script>

<style lang='scss' scoped>
.bgc {
    padding: 0 10px;
    background-color: #fff;
    min-height: calc(100vh - 140px);
    position: relative;
}

.dialog-footer {
    text-align: center;
    display: block;
}

.mx-1 {
    margin: 5px;
}

::v-deep .el-card__body {
    padding: 0;
}

::v-deep .el-checkbox {
    position: absolute;
    top: 15px;
    right: -10px;

    .el-checkbox__label {
        padding: 0;
        height: 0;
        opacity: 0;
    }
}

::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.el-form-item {
    margin: 0 auto;
    width: 100%;
}

.selector-wrap {
    margin: 0px 0 10px;
}

.item {
    width: 200px;
    margin: 0 10px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.inline-input {
    margin-right: 5px;
    width: 300px;
}
</style>
